@import '~@betwixt/common/src/scss/variables.scss';

/*
**************
* section-price
**************
*/

.section-price{
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;

    .try_now_cta{
        line-height: 24px;
        text-align: center;
        color: white;
        background-color: $orange;
        font-size: 22px;
        padding: 10px 30px;
        border-radius: 50px;
        display: inline-block;
        margin: auto;
        margin-bottom: 10px;
    }
    .base_price{
        color: red;
        margin: 10px auto -15px;
        text-decoration: line-through;
    }
    .price-block{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 30px;
        line-height: 25px;
        margin-top: 20px;
        color: $black;

        .price{
            font-weight: 900;
        }
        .info{
            font-size: 18px;
            line-height: 32px;
        }
        .extra{
            font-size: 17px;
            color: red;
            line-height: 40px;
            font-weight: 600;
        }
    }
    p{
        text-align: center;
        padding-top: 10px;
        color: $blue_primary;
        font-weight: 700;
    }

}