@import '~@betwixt/common/src/scss/variables.scss';

main{
    
    /*
    **************
    * productCard
    **************
    */

    &#LandingWidget{

        .container{
            header{
                position: relative;
                text-align: center;
                margin: 20px auto;
                
                h1{
                    color: white;
                    font-size: 45px;
                    font-weight: 300;
                    display: block;

                    b{
                        font-weight: 800;
                    }

                    badge{
                        font-size: 20px;
                        border-radius: 20px;
                        padding: 4px 20px;
                        background-color: $blue_primary;
                    }
                }

                h2{
                    margin: 70px auto 70px;
                    color: white;
                    font-weight: 300;
                    font-size: 32px;
                }

                .back{
                    position: absolute;
                    top: 10px;
                    left: 0;
                    font-size: 17px;
                    color: $grey;

                    @include media-breakpoint-down(sm){
                        position: relative;
                    }

                    svg{
                        font-size: 50px;
                        vertical-align: middle;
                        padding-right: 10px;
                        padding-bottom: 4px;
                    }
                }
            }

            .productCard_MM_WIDGET{    
                color: black;
                padding: 7px;

                .browser-header{
                    background-color: #e6e6e6;
                    border-radius: 16px;
                    padding: 8px;
                    text-align: center;
                    position: relative;

                    .cta{
                        position: absolute;
                        left: 20px;
                        top: 50%;
                        z-index: 2;
                        transform: translateY(-50%);
                        display: flex;
                        flex-direction: row;

                        span{
                            width: 20px;
                            height: 20px;
                            background-color: #f2f2f2;
                            margin: 4px;
                            border-radius: 10px;
                        }
                    }
                    .url{
                        display: inline-block;
                        color: white;
                        background-color: #b3b3b3;
                        padding: 3px 10%;
                        border-radius: 20px;
                    }
                }
                .browser-content{
                    padding: 20px;
                    
                    @include media-breakpoint-down(sm){
                        padding: 20px 0px;
                    }

                    .site-text{
                        background-color: #e6e6e6;
                        height: 30px;
                        width: 100px;
                        margin: 20px;
                        border-radius: 20px;
                    }
                    .sx,
                    .dx{
                        display: inline-block;
                        width: auto;
                        vertical-align: middle;
                    }

                    .sx{
                        width: 40%;

                        @include media-breakpoint-down(sm){
                            display: none;
                        }
                    }
                    .dx{
                        width: 60%;

                        @include media-breakpoint-down(sm){
                            width: 100%;
                        }
                    }
                }
            }

            .info{

                @include media-breakpoint-down(sm){
                    padding: 0;
                    list-style: none;
                }

                h4{
                    color: white;
                    list-style: circle;
                }
                code{
                    background-color: black;
                    padding: 10px 20px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    display: inline-block;
                    font-size: 15px;
                }
            }
        }

    }
}