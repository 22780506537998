@import '~@betwixt/common/src/scss/variables.scss';

* { 
    //transition: all 0.3s ease-in-out;
 }

html{
  
    height:100%;
    -webkit-overflow-scrolling: touch;

    body {
        height:100%;
        font-family: Lato, sans-serif;
        background-color: $dark;
        
        &.darkMode{
            background-color: #2d2d2d;
        }

        &.headerInSticky{
            #root{
                .App{
                    main{
                        padding-top: 170px
                    }
                }
            }
        }

        #root{

            height: 100%;

            .App{

                height: 100%;

                @include media-breakpoint-down(sm) {
                    overflow-x: hidden;
                }
        
                main{
                    height: auto;
                    position: relative;
                    
                    > section {
                        padding: 2vh 3vw;

                        header{
                            padding: 0.6rem 1rem; 

                            h1{
                                font-size: 34px;
                                color: $blue;
                            }
                            p{
                                font-size: 18px;
                                font-weight: 300;
                                margin-bottom: 30px;
                            }
                        }

                        .content{
                            padding: 0 1rem 1rem;
                        }

                    }

                    .section{
                        border-radius: 20px;
                        margin-top: 1vh;
                        margin-bottom: 4vh;
                        position: relative;

                        & .section{
                            width: 100%;

                            @include media-breakpoint-down(sm) {
                                padding: 0 20px; 
                            }
                            @include media-breakpoint-down(sm) {
                                padding: 0; 
                            }

                            @at-root .withAside header{
                                padding: 25px 0 !important;
                            }
                        }
                        
                        header{
                            position: relative;

                            &:first-child{

                                @include media-breakpoint-down(sm) {
                                    padding: 25px;
                                    text-align: center;
                                }
                            }
                        }

                        &.primary{
                            background-color: white;
                        }

                        &.secondary {
                            background-color: #d4d4d4;
                            box-shadow: none;
                        }

                        &[data-pagename="welcome"]{
                            background-color: transparent;
                            box-shadow: none;
                        }

                        h1{
                            font-size: 34px;
                            color: $blue;
                        }

                        p{
                            font-size: 18px;
                            font-weight: 400;
                            margin-bottom: 30px;
                        }

                    }
                }

                #tooltip{
                    left: 0;
                    right: 0;
                    color: white;
                    padding: 5px;
                    border-bottom: 1px solid #f4f4f4;
                    cursor: pointer;
                    position: fixed;
                    z-index: 9999;

                    #toggle{
                        position: fixed;
                        z-index: 100;
                        cursor: pointer;
                        display: none;
                    }

                    svg{
                        margin: 0 10px
                    }

                    &.development{
                        background-color: #1787f6;
                        #toggle{
                            color: #1787f6;
                        }
                    }
                    &.test{
                        background-color: #fecc47;
                        #toggle{
                            color: #fecc47;
                        }
                    }

                    &.compact{
                        padding: 0;
                        .container{
                            display: none;
                        }
                        #toggle{
                            display: block;
                        }
                    }
                }

            }
        }
    }
}

.container_sticky{
    width: 100%;
    
    &.sticky{
        left: 0;
        z-index: 2;
        position: absolute;
    }
}

.hidden{
    display: none !important;
}