@import '~@betwixt/common/src/scss/variables.scss';

#WelcomePage{
    height: 100% !important;
    position: relative;
    padding-bottom: 0 !important;
    display: flex;
    justify-content: center;
    z-index: 2;


    .content{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 1;
            
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
        
        #logo{
            height: 80px;
            margin: 7%;
        }
        .csc{
            width: 45%;
            text-align: center;
            margin-left: 7%;

            @include media-breakpoint-down(sm) {
                width: 80%;
                margin-left: 0;
            }

            .slogan{
                font-family: 'Raleway', sans-serif;
                font-size: 1.7em;
                padding: 10px 30px;
                color: #a9a0a0;
                display: inline-block;
            }

            p{
                font-family: 'Raleway', sans-serif;
            }
    
            .btn-default{
                color: $blue;
            }

            .btn-login{
                border-radius: 7px;
                padding: 7px 20px;
                text-transform: uppercase;
                color: #ff8100;
                margin: 10px auto;
                display: inline-block;
                text-decoration: none;
    
                &:hover{
                    background-color: rgba(255,255,255,0.1);
                }
            }
        }
    }
}

.canvas_particles{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
