@import '~@betwixt/common/src/scss/variables.scss';

body{
    &.assistance{
        background-color: white;
    }
}

main{
    &.section{
        &.assistance{
            display: flex;
            flex-direction: column;
            height: 100% !important;

            #Assistance{
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }

                .section{
                    width: 100%;
                    padding: 20px;
                    background-color: white !important;
                        
                    &.s_faq{
                    }

                    &.s_contact{
                        width: 70%;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
        
                    }

                    h2, p{
                        color: #104d84 !important;
                        text-align: left;
                    }
                    a{
                        color: white;
                        text-decoration: underline;
                    }

                    .faq{
                        background-color: #067ee8 !important;
                        color: white !important;

                        @include media-breakpoint-down(sm) {
                            padding: 30px;
                        }

                        h2{
                            color: white !important;
                            padding: 20px 30px;
                        }

                        ul{
                            padding: 0 30px;

                            li{
                                h3{
                                    font-size: 20px;
                                    color: #ffcd37 !important;
                                    font-weight: 400;
                                }
                                p{
                                    color: white !important;
                                }
                            }
                        }
                    }

                    .contact{
                        color: #3ea3fd;
                        background-color: #f5f8fb !important;

                        @include media-breakpoint-down(sm) {
                            padding: 30px;
                        }
                        
                        h2{
                            padding: 10px 0;
                        }

                        input,
                        select,
                        textarea{
                            background-color: white;
                            border: 1px solid #3ea3fd;
                        }
                        .send-success{
                            font-size: 17px;
                            color: green
                        }
                    }
                }
            }
        }
    }
}