@import '~@betwixt/common/src/scss/variables.scss';

#footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 1rem 1rem;

    .container-menu{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: center;
        }
    }

    &.minimal_row{
        padding: 2rem 0;
    }

    nav{
        #languageSelector{
            .label{
                font-size: 13px;
                color: $grey_medium;
            }

            button {
                font-size: 15px;
                border: none;
                background: transparent;
                color: $grey_medium;
                border-radius: 4px;
                margin-left: 7px;
                line-height: 12px;
                padding: 5px;

                &.actived{
                    font-weight: 900;
                    border: 1px solid;
                }
                &:focus{
                    outline: none;
                }
            }
        }

        a{
            color: $grey_medium;
            font-size: 13px;
            padding: 7px 12px;
        }
    }

    .logo{
        width: 100px;
        margin: 20px auto 15px -15px;
    }
}