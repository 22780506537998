@import '~@betwixt/common/src/scss/variables.scss';

nav{
    &#primaryMenu{
        display: flex;
        flex-direction: row;
        align-items: center;

        transition: all 0.3s;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        ul{
            position: relative;
            z-index: 2;
            list-style: none;
            padding: 0;
            margin: 0;

            li{
                padding: 7px 12px;
                display: inline-block;
                text-transform: capitalize;
                font-size: 15px;


                &#btn-signup,
                &#btn-login{
                    @include media-breakpoint-up(sm) {
                        background-color: $orange;
                        border: 1px solid $orange; 
                        border-radius: 10px;
                    }
                }

                
                @include media-breakpoint-down(sm) {
                    border-bottom: 1px solid #575756;
                    padding: 20px 20px;
                    font-size: 18px;
                    border-radius: 0 !important;
                    display: block;
                }
                
                &#btn-logout{
                    color: $blue;
                    cursor: pointer;
                    border: 1px solid $orange; 
                    border-radius: 10px;

                    @include media-breakpoint-down(sm) {
                        margin-left: 10px;
                    }
                }

                a{
                    color: #6f6f6e;

                    @include media-breakpoint-down(sm) {
                        color: #d0d4e0
                    }

                    &:hover{
                        color: $blue;
                        text-decoration: none;
                    }

                    &.active{
                        color: $orange !important;
                        font-weight: 700;
                    }

                }

            }   
        }

        @include media-breakpoint-down(sm) {
            top: 0;
            left: -80%;
            width: 80%;
            height: 100%;
            background-color: rgba(51, 51, 51, 0.95);
            position: fixed;
            z-index: 100;
            padding-top: 140px;

            &.opened{
                left: 0;
            }
        }
    }
    
    &#secondaryMenu{
        padding: 15px;

        ul{
            position: relative;
            list-style: none;
            padding: 3px 0;
            margin: 0;

            li{
                text-transform: capitalize;
                font-size: 17px;
                
                a{
                    position: relative;
                    display: block;
                    padding: 8px 25px;
                    border-radius: 4px;
                    text-align: right;
                    color: #575756; 

                    @include media-breakpoint-down(sm) {
                        text-align: center;
                    }

                    &:hover,
                    &.active{
                        background-color: $grey_light;
                        color: #575756;
                        text-decoration: none;
                        z-index: 2;
                    }
                }

                &:last-child{
                    border-bottom: transparent;
                }
            }
        } 
    }

    &.tertiaryMenu{
        
        @at-root .container_sticky{
            &.sticky{

                background-color: rgba(233, 233, 233, 0.83);
                
                nav{
                    &.tertiaryMenu {

                        overflow-x: scroll;
                        white-space: nowrap;
                        min-height: auto !important;

                        h1{
                            display: inline-block;
                            margin: 0px !important;
                            padding: 0px 30px !important;
                            font-size: 20px !important;
                            line-height: 42px;
                        }
                        ul{
                            top: 0px !important;
                            display: inline-block;
                            
                            li{
                                border-bottom: 1px solid transparent !important;
                                display: inline-block !important;
                                color: #757573 !important; 

                                &:hover{
                                    color: #2e2e2e !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        position: relative;
        min-height: 100px;

        h1{
            @at-root .withAside &{
                padding-left: 0;
                padding-right: 0;
            }
            padding: 30px;

            @include media-breakpoint-down(sm) {
                padding: 30px 10px;
                text-align: center;
            }
        }

        ul{
            position: absolute;
            top: 30px;
            right: 20px;
            padding: 0;
            margin: 0;

            @include media-breakpoint-down(sm) {
                position: relative;
                top: 0;
                right: 0;
            }

            li{
                color: #c0c0bf;
                font-size: 16px;
                padding: 7px 20px;
                display: inline-block;
                cursor: pointer;

                &:hover{
                    color: #5b5b5a;
                }

                @include media-breakpoint-down(sm) {
                    display: block;
                    border-bottom: 1px solid #c0c0bf;

                    &:last-child{
                        border-bottom: 1px solid transparent;
                    }
                }
            }
        }
    }
}    

#darkflow{
    background: #d0d4e0;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1;
}