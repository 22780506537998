@import '~@betwixt/common/src/scss/variables.scss';

main{
    
    /*
    **************
    * productCard
    **************
    */

    .productCard_MM_API{    
        color: black;
    }

}