@import '~@betwixt/common/src/scss/variables.scss';

main > 
header{

    position: relative;
    height: 140px;
    width: 100%;
    z-index: 99;
    background-color: transparent;

    &.header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 110px;
        
        @include media-breakpoint-down(sm) {
            min-height: 140px;
        }

        &.sticky{
            nav{
                a {
                    color: white !important;
                }
            }
        }
    }

    &.primaryMenu_Mobile_Opened {
        max-width: 100%;
        transition: all 0.3s;

        .section{
            width: 100% !important;
            max-width: 100%;
        }
        #logo{
            position: fixed;
            top: 40px;
            z-index: 120;
        }
    }

    &[data-pagename="welcome"]{
        #logo{
            left: 50%;
            margin-left: -138px;
        }
        nav{
            &#primaryMenu{
                display: none; 
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
        }
    }

    &.sticky{
        position: fixed;
        top:0;
        left:0;
        min-height: 70px;
        height: auto;
        background-color: rgba(33, 37, 41, 0.75);
        max-width: 100%;

        #logo{
            height: 50px;

            svg{
                height: 50px;
                .st0,
                .st4{
                    fill: white;
                }
            }
        }
        
        #togglePrimaryMenu{
            top: 20px;
        }

        nav{
            a {
                color: #6f6f6e !important;
            }
        }
    }

    svg{
        &#logo{
            height: 60px;
            width: 230px;
            transform: translate(-10px);
        }
    }

    #logo{
        transition: all 0.3s;
        z-index: 101;

        svg{
            height: 60px;
            width: 230px;
            transform: translate(-10px);
        }

        transition: 0.3s all;
        
        &.beWhite{
            left: calc((80% - 230px)/2.3);
            margin-left: auto;
            .st1{
                fill: white;
            }
        }
        &.twixtWhite{
            .st0,
            .st4{
                fill: white;
            }
        }
    }

    #togglePrimaryMenu{
        display: none;
        position: absolute;
        width: 50px;
        height: 33px;
        right: 0;
        top: 55px;
        z-index: 3;

        border-radius: 4px;
        padding: 5px 7px;
        background-color: $orange;

        div{
            background-color: white;
            width: 30px;
            height: 3px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            border-radius: 4px;

            &:nth-child(1){
                top: 7px;
            }
            &:nth-child(2){
                top: 15px;
            }
            &:nth-child(3){
                top: 23px;
            }
        }

        &:hover{
            background-color: $orange;
            div{
                background-color: white;
            }
        }
        
        &.opened{
            div{
                &:nth-child(1){
                    top: 5px;
                    transform: rotate(45deg)translate(-4px, 18px);
                }
                &:nth-child(2){
                    display: none;
                }
                &:nth-child(3){ 
                    top: 5px;
                    transform: rotate(135deg)translate(18px, 4px);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            display: block;
            right: calc( (20% - 50px) / 2);
        }
    }
}