
main{
    &#UserPublicPage{

        .container{
            .brandClient{
                width: 230px;
                margin: 30px auto;
                margin: auto;
                display: block;
            }
            .betwixt_mm_widget{
                .betwixt_mm_widget__app{
                    background-color: white;
                
                    .swiper-container{
                
                    }
                }
            }
        }

    }
}