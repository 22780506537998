@import "~bootstrap/scss/mixins/_breakpoints";

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Raleway:wght@200;400;600;700;800&display=swap');

/*
@font-face {
    font-family: Lato;
    src: local("Lato"),
         url(/assets/fonts/Lato/Lato-Regular.ttf) format('ttf');
}
@font-face {
    font-family: Lato;
    src: local("Lato-Italic"),
         url(/assets/fonts/Lato/Lato-Italic.ttf) format('ttf');
    font-style: italic;
}
@font-face {
    font-family: Lato;
    src: local("Lato-LightItalic"),
         url(/assets/fonts/Lato/Lato-LightItalic.ttf) format('ttf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: Lato;
    src: local("Lato-Light"),
         url(/assets/fonts/Lato/Lato-Light.ttf) format('ttf');
    font-weight: 300;
}
@font-face {
    font-family: Lato;
    src: local("Lato-Hairline"),
         url(/assets/fonts/Lato/Lato-Hairline.ttf) format('ttf');
    font-weight: 200;
}
@font-face {
    font-family: Lato;
    src: local("Lato-Bold"),
         url(/assets/fonts/Lato/Lato-Bold.ttf) format('ttf');
    font-weight: 700;
}
@font-face {
    font-family: Lato;
    src: local("Lato-Black"),
         url(/assets/fonts/Lato/Lato-Black.ttf) format('ttf');
    font-weight: 900;
}





@font-face {
    font-family: OpenSans;
    src: local("OpenSans"),
         url(/assets/fonts/Open_Sans/OpenSans-Regular.ttf) format('ttf');
}
@font-face {
    font-family: OpenSans;
    src: local("OpenSans-Italic"),
         url(/assets/fonts/Open_Sans/OpenSans-Italic.ttf) format('ttf');
    font-style: italic;
}
@font-face {
    font-family: OpenSans;
    src: local("OpenSans-Light"),
         url(/assets/fonts/Open_Sans/OpenSans-Light.ttf) format('ttf');
    font-weight: 300;
}
@font-face {
    font-family: OpenSans;
    src: local("OpenSans-LightItalic"),
         url(/assets/fonts/Open_Sans/OpenSans-LightItalic.ttf) format('ttf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: OpenSans;
    src: local("OpenSans-Bold"),
         url(/assets/fonts/Open_Sans/OpenSans-Bold.ttf) format('ttf');
    font-weight: 700;
}
@font-face {
    font-family: OpenSans;
    src: local("OpenSans-ExtraBold"),
         url(/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf) format('ttf');
    font-weight: 900;
}
*/

$width_mobile_max: 768px;
$width_tablet_max: 1200px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

$grey_superlight: #f9f9f9;
$grey_light: #f5f5f7;
$grey_light_2: #e6e6e6; 
$grey_light_3: #efeff1; 
$grey_light_medium_light: #e2e2e2;
$grey_light_medium: #dcdbdb;
$grey: #ccc;
$grey_medium: #808080;
$grey_dark_light: #464545;
$grey_dark: #333333;
$dark: #2d2d2d;
$black: #1a1a1a;


$blue_custom_1: #b6c1cc;
$blue_custom_1_medium: #415971;

$blue_white: #ebedef;
$blue_superlight: #eef8ff;
$blue_light: #87c6ff;
$blue_primary_light: #52abfb;
$blue_primary: #0084FA;
$blue: #1e91d8;
$blue_dark: #00427d;

$orange_1: #ffe78c;
$orange: #ffcd36;
$orange_medium: #fb8c2b;

$green: #80d005;