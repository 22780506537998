@import '~@betwixt/common/src/scss/variables.scss';

#PrivacyPolicy,
#CookiePolicy{

    .iub_content,
    .iubenda_legal_document{
        padding: 2rem;

        h1, h2, h3, h4, h5, h6, ul, p{
            color: $grey_dark !important;
        }
        h2{
            font-size: 22px;
            text-align: left;
            margin-top: 2rem;
        }
        h3{
            font-size: 20px;
            text-align: left;
        }
        h4{
            font-size: 18px;
        }

        p{
            font-size: 14px !important;
            font-weight: 400 !important;
            margin-bottom: 10px !important;
        }

    }

}