@import '~@betwixt/common/src/scss/variables.scss';

#Modules{
    .module{
        color: white;
        margin: 1rem auto;
        border-radius: 20px;
        background-color: $grey_dark;

        &:first-child{
            margin-top: 0;
        }

        .moduleImage{
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            min-height: 200px;

            @include media-breakpoint-down(sm) {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .info{
            padding: 3rem 2rem;

            @include media-breakpoint-down(sm) {
                padding: 2rem;
            }

            h3 {
                font-size: 29px;
                font-weight: 700;
            }

            p{
                font-size: 15px;
                font-weight: 400;
            }

            a{
                color: white;
                font-size: 14px;
                font-weight: 400;
                background-color: $black;
                padding: 12px 20px;
                margin-top: 1rem;
                border-radius: 5px;
                display: inline-block;
            }
        }
    }
}