@import '~@betwixt/common/src/scss/variables.scss';

main {
    
    /*
    **************
    * productCard
    **************
    */

    .productCard_MM_Shop {    
        
        /*
        **************
        * header
        **************
        */

        img{
            width: 100%;
        }

        header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                text-align: center;
            }
            @include media-breakpoint-only(xs){
                padding: 3rem 2rem;
            }
            @include media-breakpoint-only(sm){
                padding: 5rem;
            }
            @include media-breakpoint-only(md){
                padding: 8rem 5rem;
            }
            @include media-breakpoint-only(lg){
                padding: 9rem 8rem;
            }
            @include media-breakpoint-only(xl){
                padding: 11rem 8rem;
            }

            h1, h2{
                color: black;
                text-align: left;
                filter: drop-shadow(2px 1px 20px #fdc860);
            }
            h1{
                font-family: 'Lato';

                .menu{
                    font-weight: 900; 
                }
                .management{
                    font-weight: 400;
                    color: $blue_primary;
                }

                @include media-breakpoint-only(xs) {
                    font-size: 32px;
                }
            }
            h2{
                font-family: 'Raleway', sans-serif;
                font-weight: 300;

                @include media-breakpoint-down(md){
                    font-size: 26px;
                }
            }

            .cta{
                display: flex;
                flex-direction: column;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                }

                a{
                    background-color: $blue_primary;
                    color: white;
                    padding: 7px 20px;
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 400;
                }
                p{
                    font-family: 'Raleway', sans-serif;
                    color: black;
                    font-size: 15px;
                    font-weight: 600;
                    padding: 10px;
                    margin: 0;
                    filter: drop-shadow(2px 1px 10px white);
                }
            }
        }

        /*
        **************
        * ipad-display
        **************
        */

        .ipad-display{
            @include media-breakpoint-only(xs){
                min-height: 24rem;
            }
            @include media-breakpoint-only(sm){
                min-height: 30rem;
            }
            @include media-breakpoint-only(md){
                min-height: 30rem;
            }
            @include media-breakpoint-only(lg){
                min-height: 27rem;
            }
            @include media-breakpoint-only(xl){
                min-height: 33rem;
            }  
        }

        /*
        **************
        * section-description
        **************
        */

        .section-description{
            margin: 10px 10% 60px;
            text-align: center;

            .title{
                font-size: 22px;
                line-height: 28px;
                margin: 0 10% 17px;
                color: black;
            }
            .extends1,
            .extends2{
                font-size: 18px;
                line-height: 25px;
                margin: 0 7% 17px;
                color: $grey_dark;
            }
            .extends2{
                font-size: 22px;
                line-height: 28px;
                margin-top: 30px;
                color: black;
            }
        }

        /*
        **************
        * section-menus
        **************
        */

        .section-menus{
            overflow: hidden;
            
            h2{
                small{
                    display: inline;
                    
                    @include media-breakpoint-down(xs){
                        display: block;
                    }
                }
            }

            img{
                margin: auto;
                display: block;

                @include media-breakpoint-only(xs){
                    width: 170%;
                    margin: auto -35%;
                }
                @include media-breakpoint-only(sm){
                    width: 140%;
                    margin: auto -20%;
                }
                @include media-breakpoint-only(md){
                    padding: 0;
                }
                @include media-breakpoint-only(lg){
                    padding: 0 5rem;
                }
                @include media-breakpoint-only(xl){
                    padding: 0 8rem;
                }
    
            }
        }

        /*
        **************
        * section-plates
        **************
        */

        .section-plates{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            border: 5px solid #f2f2f2;
            padding: 0 30px 0 0;
            border-radius: 20px;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                padding: 0;
                flex-direction: column;
                margin: 20px;
            }

            @include media-breakpoint-only(md) {
                margin: auto 3rem !important;
            }

            @include media-breakpoint-up(md) {
                margin: auto 7rem;
            }

            h2{
                small{
                    text-align: left;

                    @include media-breakpoint-down(sm) {
                        text-align: center;
                    }
                }
            }

            img{
                width: 75%;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }

        /*
        **************
        * other
        **************
        */

        .other{
            display: flex;
            flex-direction: row;
            margin: 3rem auto 2rem;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                margin: 3rem 20px 2rem;
            }

            @include media-breakpoint-only(md) {
                margin: 3rem 2rem !important;
            }

            @include media-breakpoint-up(md) {
                margin: 3rem 7rem 2rem;
            }

            /*
            **************
            * section-ingredients
            **************
            */

            .section-ingredients{
                width: 60%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                img{
                    width: 100%;
                    margin: 20px auto;
                    display: block;

                    @include media-breakpoint-down(md) {
                        width: 90%;
                    }
                }
            }

            /*
            **************
            * section-other
            **************
            */

            ul{
                &.section-other{
                    width: 35%;
                    margin-left: 5%;
                    padding: 0;
                    list-style: none;
                        
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin-left: 0;
                        margin-top: 2rem;
                    }

                    li{
                        padding: 12px;
                        border-bottom: 1px solid #e6e6e6;
    
                        &:last-child{
                            border-bottom: none;
                        }

                        h2{
                            text-align: left;
                            font-size: 29px;

                            @include media-breakpoint-down(md) {
                                text-align: center;
                                font-size: 27px;
                            }
                            
                            small{
                                margin-top: 5px;
                                font-size: 17px;

                                @include media-breakpoint-down(md) {
                                    text-align: center;
                                }
                            }
                        }

                    }
                }
            }
        }

        /*
        **************
        * section-configuration
        **************
        */

        .section-configuration{
            padding-bottom: 5rem;

            .title{
                padding: 0 0 2rem;

                @include media-breakpoint-only(xs) {
                    padding: 0 0 1rem;
                }
            }

            ul{
                &.boxes{
                    display: flex;
                    flex-direction: row;
                    padding: 0;
                    margin: auto 3rem;

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                    
                    @include media-breakpoint-only(xs) {
                        margin: auto 1rem;
                    }
                    
                    & > li{
                        border-radius: 20px;
                        min-width: 32%;
                        list-style: none;
                        box-shadow: 0 0 30px 0px #ccc;
                        min-height: 320px;

                        @include media-breakpoint-down(md) {
                            flex-direction: column;
                            margin: auto auto 25px !important;
                        }
                        
                        @include media-breakpoint-down(sm) {
                            max-width: 100%;
                        }
                        
                        @include media-breakpoint-only(md) {
                            max-width: 70%;
                        }
                        
                        h3{
                            font-family: 'Raleway', sans-serif;
                            font-size: 25px;
                            font-weight: 700;
                        }

                        /*
                        **************
                        * allergens-vegetarian-vegan
                        **************
                        */

                        &.allergens-vegetarian-vegan{
                            color: white;
                            background-color: #1890ff;
                            border: 5px solid #f2f2f2;
                            position: relative;

                            div{
                                position: relative;
                                z-index: 2;


                                @include media-breakpoint-down(xs){
                                    padding: 3rem 25%;
                                }
                                @include media-breakpoint-only(sm){
                                    padding: 3rem 7rem;
                                }
                                @include media-breakpoint-only(md){
                                    padding: 3rem 7rem;
                                }
                                @include media-breakpoint-only(lg){
                                    padding: 3rem 4rem;
                                }
                                @include media-breakpoint-only(xl){
                                    padding: 3rem 5rem;
                                }
                            }

                            img{
                                z-index: 1;
                                position: absolute;
                                bottom: 5%;
                                left: 5%;
                                width: 90%;
                            }
                        }

                        /*
                        **************
                        * prices
                        **************
                        */

                        &.prices{
                            color: #40330e;
                            background-color: white;
                            border: 5px solid white;
                            margin: auto 15px;
                            
                            div{
                                padding: 2rem 2rem 0;

                                ul{
                                    padding: 0 0 0 20px;
                                    list-style: disc;

                                    li{
                                        box-shadow: none;
                                    }
                                }
                            }

                            img{
                                padding: 0 0.5rem;
                            }
                        }

                        /*
                        **************
                        * types-menu-plates-ingredients
                        **************
                        */

                        &.types-menu-plates-ingredients{
                            color: #40330e;
                            background-color: $orange;
                            border: 5px solid #f2f2f2;

                            div{
                                padding: 2rem 2rem 0;
                            }

                            .switch{
                                padding: 0;

                                img{
                                    height: 25px;
                                }
                                p{
                                    text-align: center;
                                    margin: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        /*
        **************
        * section-widget
        **************
        */

        .section-widget{
            display: flex;
            flex-direction: column;
            margin: 0 auto 40px;
            padding: 20px 4rem;
            color: $dark;

            @include media-breakpoint-down(sm) {
                padding: 20px;
            }

            & > div{
                &:first-child{
                    align-items: center;
                    display: flex;
                }
            }

            p{
                font-size: 17px;
                font-weight: 400;
                text-align: center;
                line-height: 32px;
                margin: 15px 22%;

            }

            a{
                color: $blue_primary;
                font-size: 18px;
                margin: 20px auto 0;
            }

            svg{
                font-size: 50px;
                vertical-align: middle;
                padding-left: 10px;
            }

        }        

    }
}